.feature {
    /* margin-bottom: 140px; */
}
.img {
    border-radius: 20px;
    border-width: 5px;
    border-color: #dee2e6;
    box-shadow: 0 4px 8px 0 rgba(182, 34, 34, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.image_2_div {
    margin-left: -100px;
}
.img1 {
    opacity: 0.96;
}
