.leftDiv {
    color: var(--black);
    justify-content: center;
    align-items: center;
}
.heading1 {
    animation-delay: 0s;
}
.heading2 {
    animation-delay: 0.5s;
}
.heading3 {
    animation-delay: 1s;
}
