@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css?family=Roboto");
@import url("https://fonts.googleapis.com/css?family=Glegoo");

:root {
    --primary: #742a2a;
    --secondary: #e53e3e;
    --white: #ffffff;
}
